import { useState,useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import * as XLSX from 'xlsx';
import TableTargetScraper from '../components/TableTargetedScraper'
import PauseCircleOutlineRoundedIcon from '@mui/icons-material/PauseCircleOutlineRounded';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import services from '../services';
import toast from 'react-hot-toast';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import classNames from 'classnames';
import FileSaver from "file-saver";
import XLSX2 from "sheetjs-style";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const TargetedScraper =()=>{
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState([]);
    const [silOpen, setSilOpen] = useState(false);
    const [durum, setDurum] = useState(null);
    const [openKazima, setOpenKazima] = useState(false);
    const [kaziBtnDis, setKaziBtnDis] = useState(true);
    const [fileName, setFileName] = useState("");
    const [liste, setListe] = useState([]);
    const [loading, setLoading] = useState(false);
    const [mesaj, setMesaj] = useState('');
    const [btnExcelLoading, setBtnExcelLoading] = useState(false);
    const [kaziDis, setKaziDis] = useState(false);

    const handleFileUpload = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
    setFileName(file.name)

      reader.onload = (event) => {
        const workbook = XLSX.read(event.target.result, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json(sheet);
        
        setData(sheetData?.map((x)=>({name:x.name,country:x.country})));
      };
  
      reader.readAsBinaryString(file);
    };

    async function DurumDegistir(secenek){
        const toastId=toast.loading('İşlem sürüyor lütfen bekleyin!');
        if(secenek === 1){
            await services.getAxios('Scraper/PauseTargetedScraper?id='+selected[0]).then((res)=>{
                toast.success('İşlem tamamlandı.',{duration:2500, id:toastId});
                GetListe();
            }).catch((err)=>{
                toast.error('İşlem sırasında bir hata oluştu.',{duration:3000, id:toastId});
            })
        }else{
            await services.getAxios('Scraper/ContinueTargetedScraper?id='+selected[0]).then((res)=>{
                toast.success('İşlem tamamlandı.',{duration:2500, id:toastId});
                GetListe();
            }).catch((err)=>{
                toast.error('İşlem sırasında bir hata oluştu.',{duration:3000, id:toastId})       
            })
        }
    }

    async function KazimaKayit(){
        const toastId=toast.loading('Kayıt tamamlanıyor lütfen bekleyin.');
        const veri={
            dosyaAdi:fileName.split(".")[0],
            adet:data.length,
            nameCountry:data
        }
    
        await services.postAxios('Scraper/InsertHedefliKazima',veri).then((res)=>{
            toast.success('Kayıt işlemi başırılı bir şekilde tamamlandı.', {id:toastId, duration:2500})
            setKaziDis(true);
            setData([])
            setFileName("");
            setOpenKazima(false)
            GetListe()
        }).catch((err)=>{
            toast.error(err.response.data || 'Kayıt işlemi sırasında bir hata oluştu.', {id:toastId, duration:4000})
        })
    }

    async function Excel(){
        setBtnExcelLoading(true)
        const dosAdi = liste.filter((x)=>x.id === selected[0])
        await services.getAxios('PostaAdresleri/TargetedScraperExcel?id='+selected[0]).then((res)=>{
            const ex = res.data?.map((x)=>({"İşletme Adı":x.name,"Ülke":x.country, 
                "Web Sitesi":x.webSitesi, "E-posta Adresi": x.adres, "Telefon Numarası": x.telefon}));

            const ws = XLSX2.utils.json_to_sheet(ex)
            const wb = {Sheets:{'data':ws}, SheetNames: ['data']}
            const excelBuffer =XLSX2.write(wb, {bookType:'xlsx', type:'array'});
            const data = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
            FileSaver.saveAs(data, dosAdi[0].dosyaAdi + '.xlsx')
        }).catch(()=>{})  
        setBtnExcelLoading(false)
    }

    function GetListe(){
        setLoading(true);
        services.getAxios('scraper/TargetedScrapers').then((res)=>{
            setListe(res.data)
            res.data?.forEach((x)=>{
                if(x.durum === 0){
                    setKaziDis(true);
                    return;
                }
            })

            if(res.data.length<0)
                setMesaj('Hiç hedefli kazıma işlemi yapılmamış.')
            setLoading(false)
        }).catch((err)=>{
            setMesaj('Bir hata oluştu.')
            toast.error('Hedefli kazıma listesi yüklenirken bir hata oluştu.', {duration:3000})
            setLoading(false)
        })
    }

    function ScraperSil(){
        setSilOpen(false)
        const toastId = toast.loading('Hedefli Kazıma işlemleri siliniyor. Lütfen bekleyin!');
    
        services.postAxios('Scraper/DeleteTargetedScraper',selected).then((res)=>{
            toast.success('Silme işlemi tamamlandı.', {id:toastId, duration:2500})
            GetListe()
        }).catch((err)=>{
            toast.error('Silme işlemi sırasında bir hata oluştu.', {id:toastId, duration:3000})
        })
    }

    useEffect(()=>{
        if(data.length > 0){
            setKaziBtnDis(false)
        }else{
            setKaziBtnDis(true);
        }
    },[data])

    useEffect(()=>{
        GetListe()
    },[])

    return(
        <>
        <div className="d-md-flex justify-content-between">
            <div className="d-md-flex gap-3">
                <div className="mb-2">
                    <Button variant="outlined" className="text-capitalize" fullWidth
                    onClick={()=>{setOpenKazima(true)}} disabled={kaziDis}
                    startIcon={<AddRoundedIcon/>}>E-posta Kazı</Button>
                </div>
                <div className="mb-2">
                    <Button variant="outlined" color="error" className="text-capitalize" fullWidth
                    disabled={selected.length > 0 ? false:true}
                    onClick={()=>{setSilOpen(true)}}
                    startIcon={<DeleteOutlineRoundedIcon/>}>Sil</Button>
                </div>
                <div className="mb-2">
                    <Button variant="outlined" color="warning" className="text-capitalize" fullWidth
                    disabled={selected.length === 1 ? durum === 0?false:true:true}
                    onClick={()=>DurumDegistir(1)}
                    startIcon={<PauseCircleOutlineRoundedIcon/>}>Duraklat</Button>
                </div>
                <div className="mb-2">
                    <Button variant="outlined" color="secondary" className="text-capitalize" fullWidth
                    disabled={selected.length === 1 ? durum !== 1?true:false:true}
                    onClick={()=>DurumDegistir(2)}
                    startIcon={<PlayCircleOutlineRoundedIcon/>}>Başlat</Button>
                </div>
                <div className="mb-2">
                <LoadingButton variant="outlined" color="success" endIcon={<SaveIcon/>} disabled={selected.length === 1 ? false:true}
                     fullWidth className="text-capitalize" loading={btnExcelLoading} onClick={Excel}>
                        Excel Olarak İndir
                    </LoadingButton>
                </div>
            </div>
        </div>
        <div>
        </div>
        <TableTargetScraper liste={liste} mesaj={mesaj} loading={loading} selected={selected} setSelected={setSelected} setDurum={setDurum}/>

        <Dialog open={openKazima} onClose={()=>setOpenKazima(false)} maxWidth="xs" fullWidth>
            <DialogTitle>E-posta Kazıma</DialogTitle>
            <DialogContent>
                <DialogContentText className="mb-2 ">
                Excel dosyası yükleyerek kazıma işlemini başlatın.
                </DialogContentText>
                <DialogContentText className="mb-2 text-danger">
                İşletme isimlerinin yer aldığı sütunun başlığı "<span className='fw-bold fs-5'>name</span>", 
                ülkelerin yer aldığı sütunun başlığı "<span className='fw-bold fs-5'>country</span> " olmak zorundadır. 
                Aksi halde dosya yükleme işlemi başarısız olacaktır.
                </DialogContentText>
                <div className='mt-4'>
                    <Button variant="outlined" className="text-capitalize" fullWidth
                        onChange={handleFileUpload}
                        startIcon={<FileUploadOutlinedIcon/>}
                        component="label" role={undefined}>
                        Dosya yükleme
                        <VisuallyHiddenInput type="file" accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'/>    
                    </Button>
                </div>
                <DialogContentText className={classNames("ms-1 mt-1",{"d-none":kaziBtnDis})}>
                    {fileName}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setOpenKazima(false)} color="error">İptal</Button>
                <Button disabled={kaziBtnDis} onClick={KazimaKayit}>Başlat</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={silOpen} onClose={()=>setSilOpen(false)} maxWidth="xs" fullWidth>
            <DialogTitle>Hedefli Kazıma İşlemleri Silinecek</DialogTitle>
            <DialogContent>
            <DialogContentText className="mb-3">
                Hedefli kazıma işleminden elde edilen veriler silinecek.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={()=>{setSilOpen(false)}}>İptal</Button>
            <Button color='error' onClick={ScraperSil}>Sil</Button>
            </DialogActions>
        </Dialog>
        </>
    )
}

export default TargetedScraper